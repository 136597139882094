import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TeamPage = () => {
  const [dialogContent, setDialogContent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [teamMembers, setTeamMembers] = useState([]);

  const handleOpenDialog = (content, type = "image") => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogContent(null);
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API}team/members`
      );
      setTeamMembers(response.data); // Assuming response.data is an array of team members
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  // Fetch team members when component mounts
  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const truncateDescription = (text) => {
    return text.length > 100 ? `${text.substring(0, 100)}...` : text;
  };
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl font-bold text-center text-gray-800 mb-12">
          MEET OUR TEAM
        </h1>

        {/* Team Member Cards */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mb-12">
          {teamMembers?.teamMembers?.map((team) => (
            <div
              key={team.name}
              className="bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 p-6 relative overflow-hidden"
            >
              <img
                src={team?.imageUrl}
                alt="Team Member"
                className="rounded-lg mb-4  w-full"
              />
              <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                {team?.name}
              </h2>
              <p className="text-gray-600">
                {" "}
                {team?.description && team.description.length > 35 ? (
                  <>
                    {truncateDescription(team.description)}
                    <button
                      className="text-blue-500 ml-2"
                      onClick={() => handleOpenDialog(team.description, "text")}
                    >
                      Read More
                    </button>
                  </>
                ) : (
                  team?.description
                )}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogActions>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <p className="text-base text-gray-700">{dialogContent}</p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TeamPage;
