import { useState } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";

function ContactUsForm() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setIsLoading((s) => !s);
    e.preventDefault();
    const serviceID = "service_dmb2t0g";
    const templateID = "template_0oxe3pm";
    const publicKey = "ZdHlZv8oVC2pij1Ps";

    const templateParams = {
      from_name: formData?.name,
      email_id: formData?.email,
      to_name: "Poma Support",
      message: formData?.message,
    };

    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);

        axios
          .post("https://pomabackend.vercel.app/support", {
            name: formData.name,
            email: formData.email,
            message: formData.message,
          })
          .then(() => {
            console.log("Support request sent successfully");
            setFormData({ name: "", email: "", message: "" });
            setOpenSnackbar(true);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Failed to send support request:", error);
          });
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <div className="bg-gray-200 py-12">
        {/* Container for the Contact Form and Info */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col gap-x-11 md:flex-row justify-between bg-white rounded-lg p-8 mb-12 space-y-5 md:space-y-0 md:space-x-8 shadow-md">
            {/* Left Side: Contact Information */}
            <div className="md:w-1/3 mb-6 md:mb-0">
              <h2 className="text-3xl font-semibold text-gray-800 mb-6">
                Contact Us
              </h2>
              <p className="text-gray-600 mb-6">
                To learn more about our work or inquire about how we can
                collaborate, fill out the form or contact us directly.
              </p>
              <div className="text-gray-600 space-y-2">
                <p>
                  Address: 147 Wheelmeadow Drive, Longmeadow, Boston, MA 01106
                </p>
                <p>Gerichtlichkeitgassse 21, 3011 Bern, BE, Switzerland</p>
                <p>Afghanistan, Kabul</p>
              </div>
            </div>

            {/* Right Side: Contact Form */}
            <div className="md:w-2/3">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col space-y-2 bg-[#f3f4f600] rounded-lg p-6 shadow-sm"
              >
                <label htmlFor="name" className="text-gray-700 font-medium">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                />
                <label htmlFor="email" className="text-gray-700 font-medium">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                />
                <label htmlFor="message" className="text-gray-700 font-medium">
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  rows="5"
                />
                <button
                  type="submit"
                  className="bg-blue-600  text-white py-2 px-20   mx-auto  rounded-lg font-semibold hover:bg-blue-700 transition duration-300 disabled:cursor-not-allowed"
                  disabled={isLoading}
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your question has been sent successfully!
        </Alert>
      </Snackbar>
    </>
  );
}

export default ContactUsForm;
