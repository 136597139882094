import ContactUsForm from "../components/ContactUsForm";
import TextOnImage from "../components/TextOnImage";
import girlsWalking from "../hompage/image/girls-walking.png";

function ContactUs() {
  return (
    <div>
      <TextOnImage url={girlsWalking} text={"CONTACT US"} />
      <ContactUsForm />
    </div>
  );
}

export default ContactUs;
