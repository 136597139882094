function TextOnImage({ url, text }) {
  return (
    <section
      className="relative w-full h-[80vh] md:h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${url})` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <h2 className="text-4xl md:text-6xl font-extrabold text-white text-center">
          {text}
        </h2>
      </div>
    </section>
  );
}

export default TextOnImage;
